<template>
    <div class="page">
        <el-form size="small" :inline="true" class="query-form" ref="searchForm" :model="searchForm"
                 @keyup.enter.native="refreshList()" @submit.native.prevent>
            <el-form-item prop="name">
                <el-input size="small" v-model="searchForm.name" placeholder="菜单名称" clearable></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="refreshList()" size="small" icon="el-icon-search">查询</el-button>
                <el-button @click="resetSearch()" size="small" icon="el-icon-refresh-right">重置</el-button>
            </el-form-item>
        </el-form>
        <div class="bg-white top">
            <vxe-toolbar :refresh="{query: refreshList}" export print custom>
                <template #buttons>
                    <el-button v-if="hasPermission('sys:menu:add')" type="primary" icon="el-icon-plus" size="small"
                               @click="add()">新增
                    </el-button>
                    <el-button v-if="hasPermission('sys:menu:del')"
                               :disabled="$refs.xTree && $refs.xTree.getCheckboxRecords().length === 0" type="danger"
                               size="small" icon="el-icon-delete" @click="del($refs.xTree.getCheckboxRecords(), 0)">删除
                    </el-button>
                </template>
            </vxe-toolbar>
            <div style="height: calc(100% - 50px);">
                <vxe-table
                        resizable
                        ref="xTree"
                        border="inner"
                        auto-resize
                        height="auto"
                        row-id="id"
                        size="small"
                        show-header-overflow
                        show-overflow
                        highlight-hover-row
                        :print-config="{}"
                        :export-config="{}"
                        :tree-config="{}"
                        :loading="loading"
                        :checkbox-config="{checkStrictly: true}"
                        :data="dataList">
                    <vxe-table-column type="checkbox" width="40px">
                    </vxe-table-column>
                    <vxe-table-column title="名称" field="name" tree-node>
                        <template slot-scope="scope">
                            <el-link type="primary" :underline="false" v-if="hasPermission('sys:menu:edit')"
                                     @click="edit(scope.row)">{{scope.row.name}}
                            </el-link>
                            <el-link type="primary" :underline="false" v-else-if="hasPermission('sys:menu:view')"
                                     @click="view(scope.row)">{{scope.row.name}}
                            </el-link>
                            <span v-else>{{scope.row.name}}</span>
                        </template>
                    </vxe-table-column>
                    <vxe-table-column title="图标" field="icon">
                        <template slot-scope="scope">
                            <i :class="scope.row.icon"></i>
                        </template>
                    </vxe-table-column>
                    <vxe-table-column title="类型" field="menuType">
                        <template slot-scope="scope">
                            <el-tag v-if="scope.row.menuType === '0'" size="small" type="success">菜单</el-tag>
                            <el-tag v-else-if="scope.row.menuType === '1'" size="small" type="info">按钮</el-tag>
                        </template>
                    </vxe-table-column>
                    <vxe-table-column title="排序号" field="sort"></vxe-table-column>
                    <vxe-table-column title="是否显示" field="isShow">
                        <template slot-scope="scope">
                            <el-tag :type="scope.row.isShow === '0' ? 'success' : 'danger'">{{scope.row.isShow === '0' ? '是' : '否'}}</el-tag>
                        </template>
                    </vxe-table-column>
                    <vxe-table-column title="菜单路由" field="href"></vxe-table-column>
                    <vxe-table-column title="权限标志" field="permission"></vxe-table-column>
                    <vxe-table-column title="操作" width="150px" fixed="right">
                        <template slot-scope="scope">
<!--                            <el-button type="text" size="small" @click="showRight(scope.row)">数据规则</el-button>-->
<!--                            <el-divider direction="vertical"></el-divider>-->
                            <el-dropdown size="small" @command="handleCommand">
                    <span class="el-dropdown-link">
                      更多<i class="el-icon-arrow-down el-icon--right"></i>
                    </span>
                                <el-dropdown-menu slot="dropdown">
                                    <el-dropdown-item v-if="hasPermission('sys:menu:view')"
                                                      :command="{method:'view', row:scope.row}">
                                        查看
                                    </el-dropdown-item>
                                    <el-dropdown-item v-if="hasPermission('sys:menu:edit')"
                                                      :command="{method:'edit', row:scope.row}">
                                        修改
                                    </el-dropdown-item>
                                    <el-dropdown-item v-if="hasPermission('sys:menu:del')"
                                                      :command="{method:'del', row:scope.row}">
                                        删除
                                    </el-dropdown-item>
                                    <el-dropdown-item v-if="hasPermission('sys:menu:add')"
                                                      :command="{method:'addChild', row:scope.row}">
                                        添加下级菜单
                                    </el-dropdown-item>
                                </el-dropdown-menu>
                            </el-dropdown>
                        </template>
                    </vxe-table-column>
                </vxe-table>
            </div>
        </div>
        <!--数据规则-->
        <el-dialog
                size="80%"
                :title="`数据规则列表，所属菜单: ${this.dataRuleTitle}`"
                :visible.sync="rightVisible">
            <data-rule-list ref="dataRuleList" @closeRight="closeRight"></data-rule-list>
        </el-dialog>
        <!-- 弹窗, 新增 / 修改 -->
        <menu-form ref="menuForm" @refreshDataList="refreshList"></menu-form>
    </div>
</template>

<script>
    import MenuForm from './MenuForm'
    import DataRuleList from './DataRuleList'
    import XEUtils from 'xe-utils'

    export default {
        components: {
            MenuForm, DataRuleList
        },
        data() {
            return {
                rightVisible: false,
                loading: false,  // 表格加载
                dataRuleTitle: '',
                searchForm: {  // 查询
                    name: ''
                },
                dataList: [], // 表格数据
                rowIds: [],
            }
        },
        activated() {
            this.refreshList()
        },
        mounted() {
        },
        methods: {
            handleCommand (command) {
                if (command.method === 'view') {
                    this.view(command.row)
                } else if (command.method === 'edit') {
                    this.edit(command.row)
                } else if (command.method === 'del') {
                    this.del(command.row, 1)
                } else if (command.method === 'addChild') {
                    this.addChild(command.row)
                }
            },
            calleArr(arr) {
                for (var i = 0; i < arr.length; i++) {
                    var data = arr[i];
                    if (data.children) {//是否还有下级
                        this.rowIds.push(data.id)
                        this.calleArr(data.children) //自己调用自己
                    } else {
                        this.rowIds.push(data.id)
                    }
                }

                return this.rowIds
            },
            // 删除
            del(data, type) {
                if (type == 1) {
                    data = [data]
                }
                let ids = this.calleArr(data).join(',')
                this.$confirm(`确定删除该条记录吗?`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.loading = true
                    this.$axios(this.api.auth.sysnewmenuRemoveById, {'ids': ids}, 'delete').then(data => {
                        this.loading = false
                        if (data && data.status) {
                            this.rowIds = [];
                            this.$message.success(data.msg)
                            this.refreshList()
                        }
                    })
                })
            },
            // 获取数据列表
            refreshList() {
                this.loading = true
                this.$axios(this.api.auth.sysnewmenuList, {}, 'get').then(data => {
                    this.loading = false
                    this.dataList = data.data
                    this.handleSearch()
                })
            },
            // 搜索
            handleSearch() {
                let filterName = XEUtils.toValueString(this.searchForm.name).trim()
                if (filterName) {
                    let options = {children: 'children'}
                    let searchProps = ['name']
                    this.dataList = XEUtils.searchTree(this.dataList, item => searchProps.some(key => XEUtils.toValueString(item[key]).indexOf(filterName) > -1), options)
                    this.$nextTick(() => {
                        this.$refs.xTree.setAllTreeExpand(true)
                    })
                }
            },
            // 重置
            resetSearch() {
                this.$refs.searchForm.resetFields()
                this.refreshList()
            },
            // 新增下级
            addChild(row) {
                this.$refs.menuForm.init('addChild', {id: '', parent: {id: row.id, name: name}})
            },
            // 新增
            add() {
                this.$refs.menuForm.init('add', {id: '', parent: {id: '', name: ''}})
            },
            // 修改
            edit(row) {
                this.$refs.menuForm.init('edit', {id: row.id, parent: {id: '', name: ''}}, row)
            },
            // 查看
            view(row) {
                this.$refs.menuForm.init('view', {id: row.id, parent: {id: '', name: ''}}, row)
            },
            // 数据规则
            showRight(row) {
                this.rightVisible = true
                this.$nextTick(() => {
                    this.$refs.dataRuleList.refreshList(row.id)
                    this.dataRuleTitle = row.name
                })
            },
            // 关闭数据规则
            closeRight() {
                this.rightVisible = false
            }
        }
    }
</script>

<style scoped>

</style>
